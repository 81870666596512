<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>系统管理</template>
      <template v-slot:secondMenu>系统配置</template>
    </breadcrumb-nav>
    <el-card style="margin: 10px 0px">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="新用户配置" name="newUser">
            <new-user-config-pane v-if="tabRefresh.newUser"></new-user-config-pane>
          </el-tab-pane>
          <el-tab-pane label="邀请配置" name="invite">
            <invite-config-pane v-if="tabRefresh.invite"></invite-config-pane>
          </el-tab-pane>
          <el-tab-pane label="系统配置" name="system">
            <system-config-pane v-if="tabRefresh.system"></system-config-pane>
          </el-tab-pane>
          <el-tab-pane label="用户头像" name="head">
            <head-config-pane v-if="tabRefresh.head"></head-config-pane>
          </el-tab-pane>
        </el-tabs>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import HeadConfigPane from './childComponents/HeadConfigPane'
import InviteConfigPane from './childComponents/InviteConfigPane'
import SystemConfigPane from './childComponents/SystemConfigPane'
import NewUserConfigPane from './childComponents/NewUserConfigPane'
export default {
  name: 'Config',
  components: {
    NewUserConfigPane,
    InviteConfigPane,
    SystemConfigPane,
    HeadConfigPane,
    BreadcrumbNav
  },
  data () {
    return {
      activeName: 'newUser',
      tabRefresh: {
        newUser: true,
        invite: false,
        system: false,
        head: false
      }
    }
  },
  methods: {
    handleTabClick (tab) {
      this.activeName = tab.name
      this.switchTab(this.activeName)
    },
    switchTab (tab) {
      for (const key in this.tabRefresh) {
        if (key === tab) {
          this.tabRefresh[key] = true
        } else {
          this.tabRefresh[key] = false
        }
      }
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

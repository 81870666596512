<template>
  <div>
    <el-button
      @click="submit"
      style="margin-bottom: 20px;"
      type="primary">提交</el-button>
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="奖励人民币金额" prop="rmb">
            <el-input type="Number" v-model.number="form.rmb"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="所需点赞数" prop="rate">
            <el-input type="Number" v-model.number="form.rate"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="活动内容" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="分享文本" prop="content">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入内容"
              v-model="form.content">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="活动时间" prop="date">
            <el-date-picker style="width:100%"
              v-model="form.date"
              type="daterange"
              align="right"
              prop="date"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="点赞示例图片" prop="icon">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleUploadSuccess"
              :headers="imgUploadHeaders"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { getConfigSetting, updateConfigSetting } from '../../../network/notify'

export default {
  name: 'InviteConfigPane',
  data () {
    return {
      form: {
        rmb: '',
        rate: '',
        date: '',
        content: '',
        title: '',
        icon: ''
      },
      inviteConfigType: 7,
      imageUrl: '',
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: {
        Authorization: sessionStorage.getItem('token')
      },
      rules: {
        rmb: [
          {
            required: true,
            message: '请输入奖励金币数量',
            trigger: 'blur'
          }
        ],
        rate: [
          {
            required: true,
            message: '请输入所需点赞数量',
            trigger: 'blur'
          }
        ],
        date: [
          {
            required: true,
            message: '请输入活动时间范围',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入分享文本',
            trigger: 'blur'
          }
        ],
        title: [
          {
            required: true,
            message: '请输入活动内容',
            trigger: 'blur'
          }
        ],
        icon: [
          {
            required: true,
            message: '请选择示例图片',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    submit () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const saveForm = {
          rmb: this.form.rmb,
          rate: this.form.rate,
          from: this.form.date[0],
          to: this.form.date[1],
          content: this.form.content,
          title: this.form.title,
          icon: this.form.icon
        }
        updateConfigSetting({
          content: saveForm,
          desc: '邀请配置信息',
          type: this.inviteConfigType
        }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('修改配置失败', 'error')
          }
          this.alertMessage('修改配置成功', 'success')
        })
      })
    },
    getDetail () {
      getConfigSetting({
        type: this.inviteConfigType
      }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          // return this.alertMessage('获取订单配置数据获取失败', 'error')
          return
        }
        const getForm = result.data.content
        this.form = {
          rmb: getForm.rmb,
          rate: getForm.rate,
          date: [getForm.from, getForm.to],
          content: getForm.content,
          title: getForm.title,
          icon: getForm.icon
        }
        if (this.form.icon) {
          this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
        }
      }, err => {
        console.log('get data err:', err)
      })
    },
    handleUploadSuccess (res, file) {
      this.form.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

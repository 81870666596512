<template>
  <div>
    <el-button type="primary" size="mini"  @click="saveUserHead">
      <slot name="title">保存</slot>
    </el-button>
    <el-form :model="form" ref="form">
      <el-form-item label="游客默认头像" label-width="200px" prop="visitIcon">
        <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleUploadVisitSuccess"
          :headers="imgUploadHeaders"
        >
          <img v-if="visitImageUrl" :src="visitImageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="会员默认头像" label-width="200px" prop="normalIcon">
        <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleUploadNormalSuccess"
          :headers="imgUploadHeaders"
        >
          <img v-if="normalImageUrl" :src="normalImageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getConfigSetting, updateConfigSetting } from '../../../network/notify'

export default {
  name: 'HeadConfigPane',
  data () {
    return {
      form: {
        visitIcon: '',
        normalIcon: ''
      },
      visitImageUrl: '',
      normalImageUrl: '',
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: {
        Authorization: sessionStorage.getItem('token')
      },
      userHeadConfigType: 4
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    handleUploadVisitSuccess (res, file) {
      this.form.visitIcon = res.data.list[0].mediaId
      this.visitImageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.visitIcon
    },
    handleUploadNormalSuccess (res, file) {
      this.form.normalIcon = res.data.list[0].mediaId
      this.normalImageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.normalIcon
    },
    getDetail () {
      getConfigSetting({
        type: this.userHeadConfigType
      }).then(res => {
        console.log('get config setting:', res)
        const result = res.data
        if (res.status !== 200) {
          // return this.alertMessage('获取订单配置数据获取失败', 'error')
          return
        }
        this.form = result.data.content
        if (this.form.normalIcon) {
          this.normalImageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.normalIcon
        }
        if (this.form.visitIcon) {
          this.visitImageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.visitIcon
        }
        console.log('userHeadConfig is:', this.form)
      }, err => {
        console.log('get data err:', err)
      })
    },
    saveUserHead () {
      console.log('this.form:', this.form)
      if (!this.form.visitIcon || !this.form.normalIcon) {
        return this.alertMessage('请上传默认头像', 'error')
      }
      updateConfigSetting({
        content: this.form,
        desc: '默认头像配置',
        type: this.userHeadConfigType
      }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改头像配置信息失败', 'error')
        }
        this.alertMessage('修改头像配置信息成功', 'success')
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

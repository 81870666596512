<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="奖励金币数量" prop="coin">
            <el-input type="Number" v-model.number="form.coin"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="发布时间" prop="publishTime">
            <el-date-picker style="width:100%"
                            v-model="form.publishTime"
                            type="date"
                            align="right"
                            prop="publishTime"
                            value-format="timestamp"
                            placeholder="请选择发布时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="公告标题" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="16">
          <el-form-item label="公告内容" prop="notify">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入内容"
              v-model="form.notify">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button
          @click="submit"
          type="primary">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getConfigSetting, updateConfigSetting } from '../../../network/notify'

export default {
  name: 'NewUserConfigPane',
  data () {
    return {
      form: {
        coin: '',
        notify: '',
        title: '',
        publishTime: ''
      },
      newUserConfigType: 2,
      rules: {
        coin: [
          {
            required: true,
            message: '请输入奖励金币数量',
            trigger: 'blur'
          }
        ],
        notify: [
          {
            required: true,
            message: '请输入公告内容',
            trigger: 'blur'
          }
        ],
        title: [
          {
            required: true,
            message: '请输入公告标题',
            trigger: 'blur'
          }
        ],
        publishTime: [
          {
            required: true,
            message: '请选择发布时间',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    submit () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        updateConfigSetting({
          content: this.form,
          desc: '新用户配置信息',
          type: this.newUserConfigType
        }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('修改配置失败', 'error')
          }
          this.alertMessage('修改配置成功', 'success')
        })
      })
    },
    getDetail () {
      getConfigSetting({
        type: this.newUserConfigType
      }).then(res => {
        console.log('get new user config setting:', res)
        const result = res.data
        if (res.status !== 200) {
          // return this.alertMessage('获取订单配置数据获取失败', 'error')
          return
        }
        this.form = result.data.content
        console.log('new user config is:', this.form)
      }, err => {
        console.log('get data err:', err)
      })
    }
  }
}
</script>

<style scoped>

</style>
